import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select, Tooltip, TimePicker, Descriptions, Image } from "antd";
import { accountListAdmin, accountRegisterAdmin, accountUpdatePasswordAdmin } from "../../../api/login";
import moment from "moment";
import request from "../../../utils/request";
import dayjs from 'dayjs';
import StaticConfig from "../../../config/StaticConfig";

const { RangePicker } = DatePicker

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isOnline: "0",

            //商家logo
            logoFile: {},
            //商家背景图
            mechartBgFile: {},

            res: {}
            //plug details  
            //产品类型：ALTERNATOR .   STARTED 
            //车类型:保留
            // 
            //录入产品的时候 有可能会录入多个OEMNo   
            // 直接叫hongNo EversPark No 一个产品对应一个编号 
            // 
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        request
            .post('pets/merchant/config/details', { phone: StaticConfig.getPhone() })
            .then(res => {
                console.log(res)

                this.setState({
                    isOnline: res.isOnline,
                    res: res
                })

                const openHours = [dayjs.unix(res.startTime), dayjs.unix(res.endTime)];
                this.formRef.current.setFieldsValue(
                    { ...res, openHours }
                );
            })
            .catch(err => {

            })
    }

    //是否上线 0离线 1是上线
    changeShopOnline = () => {
        const { isOnline } = this.state
        let isNewOnline = ''
        if (isOnline == "0") {
            isNewOnline = '1'
        } else {
            isNewOnline = '0'
        }
        request
            .post('pets/merchant/config/isOnline', { phone: StaticConfig.getPhone(), isOnline: isNewOnline })
            .then(res => {
                console.log(res)
                this.setState({
                    isOnline: res.isOnline
                })
            })
            .catch(err => {

            })
    }

    onFinish = (value) => {
        const { isOnline, logoFile, mechartBgFile } = this.state
        const {
            phone,
            name,
            address,
            openHours,
            logo,
            backgroudLogo
        } = value

        const startTime = dayjs(openHours[0]).unix()
        const endTime = dayjs(openHours[1]).unix()

        const formData = new FormData();
        formData.append('phone', StaticConfig.getPhone());
        formData.append('name', name);
        formData.append('address', address);
        formData.append('startTime', startTime);
        formData.append('endTime', endTime);
        formData.append('isOnline', isOnline);
        formData.append('type', StaticConfig.SHOP_TYPE())
        formData.append('shopId', StaticConfig.getShopId())


        if (logoFile != undefined || logoFile.file) {
            formData.append('logo', logoFile.file);
        }

        console.log(mechartBgFile)

        if (mechartBgFile != undefined || mechartBgFile.file) {
            formData.append('backgroundLogo', mechartBgFile.file);
        }

        request
            .post('pets/merchant/config/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                localStorage.setItem('shopId', res._id)
                message.open({
                    type: 'success',
                    content: '成功！'
                })
                this.getData()
            })
            .catch(err => { })

    }

    onLogoChange = (file) => {
        console.log(file)
        this.setState({ logoFile: file })
    }

    onBackgroudLogoChange = (backgroudLogo) => {
        console.log(backgroudLogo)
        this.setState({ mechartBgFile: backgroudLogo })
    }

    render() {
        const { data, isOnline, res } = this.state
        const uploadProps = {
            showUploadList: true,
            maxCount: 1,
            accept: "image/*", // 只接受图片文件
            beforeUpload: () => false // 阻止文件自动上传
        };

        return (
            <div className="pets-config-container">
                <div className="pets-config-search">
                    <Form
                        ref={this.formRef}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={res}
                        onFinish={this.onFinish}
                    >

                        <Form.Item label="名称" name="name"
                            rules={[
                                {
                                    required: true,
                                }]}
                        >
                            <Input
                            />
                        </Form.Item>

                        <Form.Item label="地址" name='address'
                            rules={[
                                {
                                    required: true,
                                }]}
                        >
                            <Input
                            />
                        </Form.Item>

                        <Form.Item label="营业时间" name='openHours'
                            rules={[
                                {
                                    required: true,
                                }]}
                        >
                            <TimePicker.RangePicker format={'HH:mm'} minuteStep={30} />
                        </Form.Item>



                        <Form.Item
                            label="logo图"
                            name="logo"
                        // rules={[
                        //     {
                        //         required: true,
                        //     }]}
                        >
                            <Upload
                                {...uploadProps}
                                onChange={this.onLogoChange}
                                name="logo"
                            >
                                <Button type="primary">logo上传</Button>
                            </Upload>
                            {
                                !res ? null :
                                    <Image src={`${StaticConfig.getBaseUrl()}uploads/${res.logo}`}></Image>
                            }

                        </Form.Item>


                        <Form.Item
                            label="背景图"
                            name="backgroudLogo"
                        // rules={[
                        //     {
                        //         required: true,
                        //     }]}
                        >
                            <Upload
                                {...uploadProps}
                                onChange={this.onBackgroudLogoChange}
                                name="backgroudLogo"
                            >
                                <Button type="primary">背景图上传</Button>
                            </Upload>
                            {
                                !res ? null :
                                    <Image src={`${StaticConfig.getBaseUrl()}uploads/${res.backgroudLogo}`}></Image>
                            }
                        </Form.Item>




                        <Button type="primary" style={{ marginLeft: '10px' }} htmlType="submit">提交</Button>
                    </Form>

                </div>
                <Tooltip title="设置店铺是否营业中" >
                    <Button className="pets-config-online" onClick={this.changeShopOnline} danger={isOnline == '1'} type="primary">{isOnline == '0' ? '上线' : '下线'}</Button>
                </Tooltip>
            </div >
        )
    }
}