class StaticConfig {
    //项目类型
    static Product = new Map([
        ["1", "美容"],
        ["2", "寄养"],
        ["3", "问诊"],
        ["4", "急诊"],
    ]);

    static getPhone() {
        return localStorage.getItem('phone')
    }

    //1是宠物店 2是宠物医疗店
    static SHOP_TYPE() {
        return localStorage.getItem('type')
    }

    //这个shopid是商户创建时候的id
    static getShopId() {
        return localStorage.getItem('shopId')
    }

    static getNetBaseUrl() {

    }

    static getFrontBaseUrl() {
        return this.getEnv() ? "http://localhost:3000/" : "https://www.chonglehulian.com/"
    }

    static getBaseUrl() {
        // return "http://127.0.0.1:7001/"
        // return "http://1.94.140.241:7001/"
        return this.getEnv() ? "http://127.0.0.1:7001/" : "https://www.chonglehulian.com/"
    }




    static getEnv() {
        return process.env.NODE_ENV === 'development'
    }
}

export default StaticConfig;