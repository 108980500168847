import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select, Tooltip, TimePicker, Descriptions, Image, Modal, Checkbox } from "antd";
import { accountListAdmin, accountRegisterAdmin, accountUpdatePasswordAdmin } from "../../../api/login";
import moment from "moment";
import request from "../../../utils/request";
import dayjs from 'dayjs';
import { render } from "@testing-library/react";
import StaticConfig from "../../../config/StaticConfig";

const { RangePicker } = DatePicker

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            record: {},
            data: []
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        const { record } = this.props.location.state;
        this.setState({ record })
        this.getData(record)
        console.log(record)
    }

    getData = (record) => {
        request
            .post('pets/merchant/yiliaoqixie/list', { orderId: record._id })
            .then(res => {
                this.setState({
                    data: res
                })
            })
            .catch(err => { })
    }

    back = () => {
        this.props.history.goBack()
    }

    columns = [
        {
            title: '图片',
            dataIndex: 'path',
            key: 'path',
            render: (text, record, index) => {
                return <Image
                    style={{ width: '30px', height: '30px' }}
                    type='primary' src={`https://www.chonglehulian.com/uploads/${record.path}`}
                ></Image>
            },
        },
        {
            title: '时间',
            dataIndex: 'createAt',
            key: 'createAt',
        },
    ]

    onLogoChange = (file) => {
        console.log('file:', file)
        const { record } = this.state
        const formData = new FormData();
        formData.append('orderId', record._id);

        if (file != undefined || file.file) {
            formData.append('yiliaoqixie', file.file);
        }

        request
            .post('pets/merchant/yiliaoqixie/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                const { record } = this.state
                this.getData(record)
            })
            .catch(err => { })
    }

    render() {
        const uploadProps = {
            showUploadList: true,
            maxCount: 1,
            accept: "image/*", // 只接受图片文件
            beforeUpload: () => false // 阻止文件自动上传
        };

        const { data } = this.state
        return (
            <div className="yiliaoqixie-details-config-container">
                <Button
                    onClick={this.back}
                    style={{ margin: '18px', width: '60px' }}
                >返回</Button>

                <Upload
                    {...uploadProps}
                    onChange={this.onLogoChange}
                    name="yiliaoqixie"
                >
                    <Button type="primary">logo上传</Button>
                </Upload>

                <Table
                    columns={this.columns}
                    dataSource={data}
                ></Table>
            </div >
        )
    }
}