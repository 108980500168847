import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select, Tooltip, TimePicker, Descriptions, Image, InputNumber } from "antd";
import request from "../../../utils/request";
import dayjs from 'dayjs';
import StaticConfig from "../../../config/StaticConfig";

export default class index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            record: null
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        const { record } = this.props.location.state;
        console.log(record);

        this.setState({ record }, () => {
            this.getData()
        })
    }

    getData = () => {
        const { record } = this.state
        request
            .post('pets/merchant/productconfigdetails/productconfigdetails/list', { productId: record._id })
            .then(res => {
                console.log(res)

                this.setState({
                    data: res
                })

            })
            .catch(err => {

            })
    }


    onFinish = (value) => {
        const {
            productTitle,
            productDes,
            productPrice,
            productDuration,
            man
        } = value

        const { _id, productType } = this.state.record

        request
            .post('pets/merchant/productconfigdetails/productconfigdetails/createConfig', {
                productId: _id,
                productTitle,
                productDes,
                productPrice,
                productDuration,
                productType,
                man
            })
            .then(res => {
                console.log(res)

                this.getData()

            })
            .catch(err => {

            })
    }


    back = () => {
        this.props.history.goBack()
    }
    render() {
        const { data } = this.state
        return (
            <div className="product-details-config-container">
                <Button
                    onClick={this.back}
                    style={{ margin: '18px', width: '60px' }}
                >返回</Button>
                <div className="product-config-search">
                    <Form
                        layout="inline"
                        ref={this.formRef}
                        wrapperCol={{
                            span: 16,
                        }}
                        // style={{
                        //     maxWidth: 600,
                        // }}
                        onFinish={this.onFinish}
                    >

                        <Form.Item label="名称" name="productTitle"
                            rules={[
                                {
                                    required: true,
                                }]}
                        >
                            <Input
                            />
                        </Form.Item>

                        <Form.Item label="描述" name="productDes"
                            rules={[
                                {
                                    required: true,
                                }]}
                        >
                            <Input
                            />
                        </Form.Item>

                        <Form.Item label="价格" name="productPrice"
                            rules={[
                                {
                                    required: true,
                                }]}
                        >
                            <InputNumber
                            />
                        </Form.Item>

                        <Form.Item label="时长" name="productDuration"
                            rules={[
                                {
                                    required: true,
                                }]}
                        >
                            <InputNumber
                                placeholder='分钟'
                            />
                        </Form.Item>

                        {StaticConfig.SHOP_TYPE() == '2' ?
                            <Form.Item label="人员" name="man"
                            >
                                <InputNumber
                                />
                            </Form.Item>
                            : null}
                        <Button type="primary" style={{ marginLeft: '10px' }} htmlType="submit">提交</Button>
                    </Form>

                </div>
                <Table
                    columns={this.columns}
                    dataSource={data}
                ></Table>
            </div >
        )
    }

    columns = [
        {
            title: '标题',
            dataIndex: 'productTitle',
            key: 'productTitle',
        },
        {
            title: '描述',
            dataIndex: 'productDes',
            key: 'productDes',
        },
        {
            title: '价格',
            dataIndex: 'productPrice',
            key: 'productPrice',
        },
        {
            title: '时长(分钟)',
            dataIndex: 'productDuration',
            key: 'productDuration',
        },
        {
            title: `${StaticConfig.SHOP_TYPE() == '1' ? "项目类型" : "医疗类型"}`,
            dataIndex: 'productType',
            key: 'productType',
            render(text, record, index) {
                return <div> {StaticConfig.Product.get(text)}</div>
            }
        },
        {
            title: '人员',
            dataIndex: 'man',
            key: 'man',
        },
    ];


}