import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select, Tooltip, TimePicker, Descriptions, Image, Card, Statistic } from "antd";
import { accountListAdmin, accountRegisterAdmin, accountUpdatePasswordAdmin } from "../../../api/login";
import moment from "moment";
import request from "../../../utils/request";
import dayjs from 'dayjs';
import StaticConfig from "../../../config/StaticConfig";

const { RangePicker } = DatePicker
const monthFormat = 'YYYY/MM';

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            selectTimeSample: 0,

            storageOutEarn: 0,
            orderEarn: 0,
            totalEarn: 0
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        // this.getData()
    }

    getData = () => {
        const { selectTimeSample } = this.state
        request.post('pets/merchant/earn', {
            shopId: StaticConfig.getShopId(),
            time: selectTimeSample
        }).then(res => {
            const { storageEarn, orderEarn } = res
            const newStorageEarn = [...storageEarn]
            let total = 0;
            newStorageEarn.forEach(item => {
                const outPrice = parseFloat(item.out_price);
                const inPrice = parseFloat(item.in_price);
                const nums = parseInt(item.nums);
                let discount = parseFloat(item.discount);

                if (discount !== 0) {
                    discount = discount / 100;
                } else {
                    discount = 1
                }

                const value = ((outPrice * discount) - inPrice) * nums;
                total += value

            });


            let orderTotal = 0

            orderEarn.forEach(item => {
                if (item.status == '1') {
                    const value = parseFloat(item.productFee)
                    orderTotal += value
                }
            })


            this.setState({
                storageOutEarn: total,
                orderEarn: orderTotal
            })

        })
            .catch(err => { })
    }



    onDatechange = (values) => {
        if (values) {
            const selectTimeSample = values.valueOf() / 1000
            this.setState({ selectTimeSample })
        } else {
            this.setState({ selectTimeSample: 0 })
        }
    }
    render() {
        const { storageOutEarn, orderEarn, totalEarn } = this.state

        return (
            <div className="pets-config-container">
                <div className="pets-config-search">
                    <DatePicker format={monthFormat} picker="month" onChange={this.onDatechange} />
                    <Button type="primary" style={{ marginLeft: '10px' }} onClick={this.getData}>确定</Button>
                    <div style={{ marginLeft: 10, color: '#6c6c6c' }}> 如何查询全部，则点击日期选择款的x按钮</div>
                </div>

                <div style={{ display: 'flex', marginTop: '30px', flexDirection: 'column' }}>
                    <Card
                        title="出库营收"
                        bordered={false}
                        style={{
                            width: 300,
                        }}
                    >
                        <Statistic title="营收" value={storageOutEarn} precision={2} />
                    </Card>

                    <Card
                        title="订单营收"
                        bordered={false}
                        style={{
                            width: 300,
                            marginTop: 30,
                            marginBottom: 30
                        }}
                    >
                        <Statistic title="营收" value={orderEarn} precision={2} />
                    </Card>

                    <Card
                        title="总营收"
                        bordered={false}
                        style={{
                            width: 300,
                        }}
                    >
                        <Statistic title="营收" value={storageOutEarn + orderEarn} precision={2} />
                    </Card>
                </div>

            </div >
        )
    }
}