import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select } from "antd";
import { accountListAdmin, accountRegisterAdmin, accountUpdatePasswordAdmin } from "../../../api/login";
import moment from "moment";
const { RangePicker } = DatePicker

const columns = [
    {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: '密码',
        dataIndex: 'password',
        key: 'password',
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime'
    }
]
export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
    }


    render() {
        const { data } = this.state
        return (
            <div className="pets-washpool-container">
                <div className="pets-washpool-search">
                    <Form
                        // layout="inline"
                        onFinish={this.onFinish}
                    >

                        <Form.Item label="地址" name='address'>
                            <Input
                            />
                        </Form.Item>


                        <Button type="primary" style={{ marginLeft: '10px' }} htmlType="submit">提交</Button>
                    </Form>
                    {/* <Table dataSource={data} columns={columns} /> */}
                </div>
            </div >
        )
    }
}