import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select, Tooltip, TimePicker, Descriptions, Image } from "antd";
import request from "../../../utils/request";
import dayjs from 'dayjs';
import StaticConfig from "../../../config/StaticConfig";


export default class index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        request
            .post('pets/merchant/productconfig/productconfig/list', { phone: StaticConfig.getPhone() })
            .then(res => {
                console.log(res)

                this.setState({
                    data: res
                })

            })
            .catch(err => {

            })
    }


    onFinish = (value) => {
        const { logoFile, mechartBgFile } = this.state
        const {
            productName,
            productType
        } = value


        request
            .post('pets/merchant/productconfig/productconfig/createConfig',
                { phone: StaticConfig.getPhone(), productName, productType })
            .then(res => {
                console.log(res)

                this.getData()
            })
            .catch(err => {

            })
    }


    render() {
        const { data } = this.state
        return (
            <div className="product-config-container">
                <div className="product-config-search">
                    <Form
                        layout="inline"
                        ref={this.formRef}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        onFinish={this.onFinish}
                    >

                        <Form.Item label={`${StaticConfig.SHOP_TYPE() == '1' ? "项目名称" : "医疗名称"}`} name="productName"
                            rules={[
                                {
                                    required: true,
                                }]}
                        >
                            <Input
                            />
                        </Form.Item>

                        <Form.Item
                            style={{
                                width: 230,
                            }}
                            label={`${StaticConfig.SHOP_TYPE() == '1' ? "项目类型" : "医疗类型"}`} name="productType"
                            rules={[
                                {
                                    required: true,
                                }]}
                        >
                            <Select

                                options={
                                    StaticConfig.SHOP_TYPE() == '1' ? [
                                        {
                                            value: '1',
                                            label: '美容',
                                        },
                                        {
                                            value: '2',
                                            label: '寄养',
                                        }
                                    ] :
                                        [
                                            {
                                                value: '3',
                                                label: '问诊',
                                            },
                                            {
                                                value: '4',
                                                label: '急诊',
                                            }
                                        ]

                                }
                            />
                        </Form.Item>

                        <Button type="primary" style={{ marginLeft: '10px' }} htmlType="submit">提交</Button>
                    </Form>

                </div>

                <Table
                    columns={this.columns}
                    dataSource={data}
                ></Table>
            </div >
        )
    }

    columns = [
        {
            title: `${StaticConfig.SHOP_TYPE() == '1' ? "项目名称" : "医疗名称"}`,
            dataIndex: 'productName',
            key: 'productName',
            // render(text, record, index) {
            //     return <div> {moment(record.confirmTime).format('YYYY-MM-DD HH:mm')}</div>
            // }
        },
        {
            title: `${StaticConfig.SHOP_TYPE() == '1' ? "项目类型" : "医疗类型"}`,
            dataIndex: 'productType',
            key: 'productType',
            render(text, record, index) {
                return <div> {StaticConfig.Product.get(text)}</div>
            }
        },
        {
            title: '详情',
            dataIndex: 'details',
            key: 'details',
            render: (text, record, index) => {
                return <Button type='primary' onClick={() => this.onDetailsClick(record)}>详情</Button>
            },
        },
        // {
        //     title: '详情',
        //     dataIndex: 'details',
        //     key: 'details',
        //     render: (text, record, index) => {
        //         return <Button>Detials</Button>
        //     },
        // },
    ];

    onDetailsClick = (record) => {
        console.log(record)
        this.props.history.push('/pets/product/details/config', { record })
    }
}