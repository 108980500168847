import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select, Tooltip, TimePicker, Descriptions, Image, Modal, Card, InputNumber } from "antd";
import { accountListAdmin, accountRegisterAdmin, accountUpdatePasswordAdmin } from "../../../api/login";
import moment from "moment";
import request from "../../../utils/request";
import dayjs from 'dayjs';
import { render } from "@testing-library/react";
import FormC from "../../../components/FormC";
import ModalC from "../../../components/ModalC";
import EditableTable from "../../../components/EditableTable";
import StaticConfig from "../../../config/StaticConfig";

const { RangePicker } = DatePicker
const outFiled2 = [
    { type: 'Divider', label: '库存管理-出库', visiable: false },
    { type: 'Input', label: '条码', name: 'barcode' },
]

const filed = [
    { type: 'Divider', label: '库存管理-入库', visiable: false },
    { type: 'Input', label: '名称', name: 'name' },
    { type: 'InputNumber', label: '数量', name: 'nums' },
    { type: 'InputNumber', label: '进价', name: 'in_price' },
    { type: 'InputNumber', label: '零售价', name: 'out_price' },
    { type: 'Input', label: '条码', name: 'barcode' },

    { type: 'DatePicker', label: '到期日期', name: 'expired_time', rules: [] },
]
const innumFiled = [
    { type: 'InputNumber', label: '数量', name: 'nums' },
]

const outFiled = [
    { type: 'InputNumber', label: '数量', name: 'nums' },
    { type: 'InputNumber', label: '折扣', name: 'discount', placeHolder: '如5折,输入50,如1折,输入10' },
]

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isModalOpen: false,
            waitOut: [],
            totalPrice: ''

        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.getData()
    }


    calculateTotalPrice = () => {
        const { waitOut } = this.state;
        let total = 0;
        waitOut.forEach(item => {
            const quantity = item.outnums || 0;
            const price = item.out_price || 0;
            const discount = item.discount || 100;


            // const actualDiscount = (100 - discount) / 100;
            // total += quantity * price * actualDiscount;
            const discountMultiplier = discount / 100;
            total += quantity * price * discountMultiplier;
        });
        this.setState({ totalPrice: total.toFixed(2) });
    }


    getData = () => {
        request
            .post('pets/merchant/storage/list', { shopId: StaticConfig.getShopId() })
            .then(res => {
                this.setState({
                    data: res
                })
            })
            .catch(err => { })
    }



    onFinish = (value) => {
        request
            .post('pets/merchant/storage/in', { ...value, shopId: StaticConfig.getShopId() })
            .then(res => {
                this.getData()
            })
            .catch(err => { })
    }



    onNumFinish = (values, record) => {
        request.post('pets/merchant/storage/fixNum',
            {
                _id: record._id,
                nums: values.nums
            }
        ).then(res => {
            this.getData()
        })
            .catch(err => {

            })
        console.log(values, record)
    }

    onOutFinish = (values, record) => {

        console.log('库存数量:', record.nums)
        console.log('出库数量:', values.nums)
        if (values.nums > record.nums) {
            message.open({
                type: 'error',
                content: '出库数量大于库存数量'
            })
            return
        }
        request.post('pets/merchant/storageout/out',
            {
                shopId: StaticConfig.getShopId(),
                goodsId: record._id,
                nums: values.nums,
                discount: values.discount,
                in_price: record.in_price,
                out_price: record.out_price
            }
        ).then(res => {
            this.getData()
        })
            .catch(err => {

            })
    }

    onOutFinish2 = (values) => {
        const barcode = values.barcode
        console.log(barcode)
    }

    handleCancel = () => {

    }

    addWait = (value) => {
        const { data, waitOut } = this.state
        const barcode = value.barcode
        const newWaitOut = [...waitOut]
        const matchingItem = data.find(item => item.barcode === barcode);
        if (matchingItem) {
            // matchingItem.discount = 0
            // newWaitOut.push(matchingItem)
            // this.setState({
            //     waitOut: newWaitOut
            // })
            const newItem = { ...matchingItem, discount: 0, outnums: 1 };
            this.setState(prevState => ({
                waitOut: [...prevState.waitOut, newItem]
            }), this.calculateTotalPrice);
        } else {
            message.open({
                type: 'error',
                content: "暂无该商品!"
            })
        }
    }


    updateWaitOutItem = (index, field, value) => {
        this.setState(prevState => {
            const newWaitOut = [...prevState.waitOut];
            newWaitOut[index] = { ...newWaitOut[index], [field]: value };
            return { waitOut: newWaitOut };
        }, this.calculateTotalPrice);
    }


    render() {
        const { data, isModalOpen, waitOut, totalPrice } = this.state;

        const columns = [
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '数量',
                dataIndex: 'nums',
                key: 'nums',
                editable: true,
            },
            {
                title: '进价',
                dataIndex: 'in_price',
                key: 'in_price',
            },

            {
                title: '零售价',
                dataIndex: 'out_price',
                key: 'out_price',
            },

            {
                title: '条码',
                dataIndex: 'barcode',
                key: 'barcode',
            },
            {
                title: '到期日期',
                dataIndex: 'expired_time',
                key: 'expired_time',
                render: (text, record) => {
                    return <div>
                        {text ? moment(text * 1000).format('YYYY-MM-DD') : null}
                    </div>
                }
            },
            {
                title: '操作',
                dataIndex: 'add_nums',
                key: 'add_nums',
                render: (text, record) => (
                    <div style={{ display: 'flex', width: '150px', justifyContent: 'space-between' }}>
                        <ModalC
                            modalWidth={800}
                            buttonText='修改'>
                            <FormC
                                labelCol={2}
                                fields={innumFiled}
                                onFinish={(values) => this.onNumFinish(values, record)}
                            />
                        </ModalC>
                        {/* <ModalC
                            modalWidth={800}
                            buttonText='出库'>
                            <FormC
                                initialValues={{ discount: '0' }}
                                labelCol={2}
                                fields={outFiled}
                                onFinish={(values) => this.onOutFinish(values, record)}
                            />
                        </ModalC> */}
                    </div>
                ),
            },
        ]

        const waitcolumns = [
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '进价',
                dataIndex: 'in_price',
                key: 'in_price',
            },
            {
                title: '零售价',
                dataIndex: 'out_price',
                key: 'out_price',
            },

            {
                title: '条码',
                dataIndex: 'barcode',
                key: 'barcode',
            },
            {
                title: '到期日期',
                dataIndex: 'expired_time',
                key: 'expired_time',
                render: (text, record) => {
                    return <div>
                        {text ? moment(text * 1000).format('YYYY-MM-DD') : null}
                    </div>
                }
            },

            {
                title: '当前数量',
                dataIndex: 'nums',
                key: 'nums',
            },
            {
                title: '出库数量',
                dataIndex: 'outnums',
                key: 'outnums',
                // render: (text, record) => {
                //     return <InputNumber onChange={(value) => {
                //         record.outnums = value
                //     }}></InputNumber>
                // }
                render: (text, record, index) => {
                    return <InputNumber
                        defaultValue={record.outnums || 1}
                        onChange={(value) => this.updateWaitOutItem(index, 'outnums', value)}
                    />
                }
            },
            {
                title: '折扣',
                width: 200,
                dataIndex: 'discount',
                key: 'discount',
                // render: (text, record) => {
                //     return <InputNumber
                //         style={{ width: '200px' }}
                //         defaultValue={0}
                //         placeholder="如5折,输入50,如1折,输入10"
                //         onChange={(value) => {
                //             record.discount = value
                //         }}></InputNumber>
                // }
                render: (text, record, index) => {
                    return <InputNumber
                        min={"1"}
                        max={"99"}
                        style={{ width: '200px' }}
                        defaultValue={record.discount || 0}
                        placeholder="如5折,输入50,如1折,输入10"
                        onChange={(value) => this.updateWaitOutItem(index, 'discount', value)}
                    />
                }
            }
        ]
        return (
            <div className="storage-in-container">
                <div style={{ gap: '10px', display: 'flex' }}>
                    <ModalC
                        modalWidth={800}
                        buttonText='新增'>
                        <FormC
                            labelCol={3}
                            fields={filed}
                            onFinish={this.onFinish}
                        />
                    </ModalC>
                    <Button type="primary" onClick={() => { this.setState({ isModalOpen: true }) }}>出库</Button>
                </div>


                <Table dataSource={data} columns={columns} />

                <Modal
                    destroyOnClose={true}
                    width={1200}
                    title="出库"
                    open={isModalOpen}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div className="storage-in-out-container">
                        <Form
                            style={{ marginBottom: '10px' }}
                            onFinish={this.addWait}
                            layout={'inline'}>
                            <Form.Item
                                key={'barcode'}
                                label={'条码'}
                                name={'barcode'}
                                rules={[{ required: true, message: `请输入条码` }]}
                            >
                                <Input></Input>
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                                确定
                            </Button>

                        </Form>

                        <div style={{ marginLeft: 10, marginBottom: 10 }}>总价格:{totalPrice}</div>
                        <Table dataSource={waitOut} columns={waitcolumns}></Table>
                    </div>
                </Modal>
            </div>
        )
    }

    handleDataChange = (record) => {
        console.log(record)
        // this.setState({})
    }
    handleCancel = () => {
        this.setState({
            isModalOpen: false,
            waitOut: [],
            totalPrice: ''
        })
    }


    handleOk = async () => {
        const { waitOut } = this.state
        console.log('waitOut:', waitOut)


        let isCorrect = true
        waitOut.forEach((item) => {
            if (item.outnums > parseInt(item.nums)) {
                isCorrect = false
                return
            }
        })
        if (isCorrect) {



            const requests = waitOut.map(item =>
                request.post('pets/merchant/storageout/out', {
                    shopId: StaticConfig.getShopId(),
                    goodsId: item._id,
                    nums: item.outnums,
                    discount: item.discount,
                    in_price: item.in_price,
                    out_price: item.out_price
                })
            );

            try {
                await Promise.all(requests);
                this.getData()
                this.setState({
                    isModalOpen: false,
                    waitOut: [],
                    totalPrice: ''
                })
            } catch (err) {
                console.error('Error in requests:', err);
            }


        } else {
            message.open({
                type: 'error',
                content: '出库数量大于实际数量!'
            })
        }

    }

}