import request from "../../utils/request";


export function loginPetsMerchant(data) {
  return request.post("pets/merchant/login", data);
}


export function accountListAdmin() {
  return request.post("/admin/pets/list");
}

export function accountRegisterAdmin(data) {
  return request.post('/admin/pets/register', data)
}

export function accountUpdatePasswordAdmin(data) {
  return request.post('/admin/pets/updatepwd', data)
}