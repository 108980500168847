import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select } from "antd";
import { accountListAdmin, accountRegisterAdmin, accountUpdatePasswordAdmin } from "../../../api/login";
import moment from "moment";
const { RangePicker } = DatePicker

const columns = [
    {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: '密码',
        dataIndex: 'password',
        key: 'password',
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime'
    }
]
export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
    }


    updatePwd = (data) => {
        accountUpdatePasswordAdmin(data)
            .then(res => {
                message.open({
                    type: 'success',
                    content: '密码修改成功'
                })
            })
            .catch(err => { })
    }

    getData = (data) => {
        accountListAdmin(data)
            .then((res) => {
                if (!res || res.length == 0) {
                    message.open({
                        type: 'error',
                        content: '暂无数据!'
                    })
                    return
                }

                let resData = []
                resData.push(res)
                this.setState({
                    data: resData
                })
            })
            .catch((err) => {

            })
    }



    onFinish = (value) => {
        console.log(value)

        accountRegisterAdmin(value)
            .then(res => {
                this.getData()
            }).catch(err => { })
    }
    render() {
        const { data } = this.state
        return (
            <div className="pets-merchant-container">
                <div className="pets-merchant-search">
                    <Form
                        layout="inline"
                        onFinish={this.onFinish}
                    >

                        <Form.Item label="手机号" name="phone">
                            <Input
                            />
                        </Form.Item>

                        <Form.Item label="密码" name='paswword'>
                            <Input
                            />
                        </Form.Item>

                        <Button type="primary" style={{ marginLeft: '10px' }} htmlType="submit">确定</Button>
                    </Form>
                    <Table dataSource={data} columns={columns} />
                </div>
            </div >
        )
    }
}