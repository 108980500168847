import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select, Tooltip, TimePicker, Descriptions, Image, Modal } from "antd";
import { accountListAdmin, accountRegisterAdmin, accountUpdatePasswordAdmin } from "../../../api/login";
import moment from "moment";
import request from "../../../utils/request";
import dayjs from 'dayjs';
import { render } from "@testing-library/react";
import FormC from "../../../components/FormC";
import ModalC from "../../../components/ModalC";

const { RangePicker } = DatePicker
const filed = [
    { type: 'Divider', label: '账户管理', visiable: false },
    { type: 'Input', label: '手机号', name: 'phone' },
    { type: 'InputNumber', label: '密码', name: 'password' },
    {
        type: 'Select', label: '类型', name: 'type', options: [
            { label: "宠物店", value: '1' },
            { label: "宠物医院", value: '2' },
        ]
    },
]


export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        request
            .post('pets/merchant/account/list',)
            .then(res => {
                this.setState({
                    data: res
                })
            })
            .catch(err => { })
    }



    onFinish = (value) => {
        request
            .post('pets/merchant/account/create', { ...value })
            .then(res => {
                this.getData()
            })
            .catch(err => { })
    }

    render() {
        const { data } = this.state

        const columns = [
            {
                title: '手机号',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '密码',
                dataIndex: 'password',
                key: 'passwrod',
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                render: (text, record) => {
                    return <div>{text == '1' ? "宠物店" : "宠物医院"}</div>
                }
            },
        ]

        return (
            <div className="storage-in-container">
                <ModalC
                    modalWidth={800}
                    buttonText='新增'>
                    <FormC
                        fields={filed}
                        onFinish={this.onFinish}
                    />
                </ModalC>

                <Table dataSource={data} columns={columns} />
            </div>
        )
    }

}