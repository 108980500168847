import axios from "axios";
import { message } from "antd";
import StaticConfig from "../config/StaticConfig";
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const instance = axios.create({
  baseURL: `${StaticConfig.getBaseUrl()}pets/api/`,
  timeout: 20000,
});

instance.interceptors.request.use(
  (config) => {
    // 在发送请求前可以进行一些处理，如添加认证信息等
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function showErrMessage(msg) {
  message.open({
    type: "error",
    content: msg,
  });
}
instance.interceptors.response.use(
  (response) => {
    const { code, message, data } = response.data;
    if (code !== 0) {
      showErrMessage(message);
      return Promise.reject(message);
    }
    return data;
  },
  (error) => {
    showErrMessage(error.message);
    return Promise.reject(error);
  }
);

export default instance;
