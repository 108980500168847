import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select, Tooltip, TimePicker, Descriptions, Image, Modal, Checkbox, Popconfirm } from "antd";
import { accountListAdmin, accountRegisterAdmin, accountUpdatePasswordAdmin } from "../../../api/login";
import moment from "moment";
import request from "../../../utils/request";
import dayjs from 'dayjs';
import { render } from "@testing-library/react";
import StaticConfig from "../../../config/StaticConfig";

const { RangePicker } = DatePicker

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            record: {},
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        const { record } = this.props.location.state;
        this.setState({
            record
        }, () => {
            this.getData(record)
        })
        console.log(record)
    }

    getData = (record) => {
        request
            .post('pets/merchant/order/zhenliao/list', { zhenliaoList: record.zhenliaoList })
            .then(res => {
                console.log('res:', res)
                this.setState({
                    data: res
                })
            })
            .catch(err => { })
    }

    back = () => {
        this.props.history.goBack()
    }

    confirm = (e) => {
        const { record } = this.state
        console.log(e)
        request
            .post('pets/merchant/order/zhenliao/delete', {
                orderId: record._id,
                zhenliaoId: e._id
            })
            .then(res => {
                // this.getData(record)
                this.props.history.goBack()
            })
            .catch(err => { })
    }

    columns = [
        {
            title: '名称',
            dataIndex: 'productTitle',
            key: 'productTitle',
        },
        {
            title: '价格',
            dataIndex: 'productPrice',
            key: 'productPrice',
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            width: 300,
            render: (text, record, index) => {
                return <div style={{ display: 'flex', gap: '10px' }}>
                    <Popconfirm
                        description="确定删除吗？"
                        onConfirm={() => this.confirm(record)}
                        okText="确定"
                        cancelText="取消"
                    >
                        <a style={{ color: 'red' }}>删除</a>
                    </Popconfirm>
                </div>
            },
        }
    ]
    render() {
        const { data } = this.state
        return (
            <div className="zhenliao-details-config-container">
                <Button
                    onClick={this.back}
                    style={{ margin: '18px', width: '60px' }}
                >返回</Button>
                <div className="product-config-search">
                </div>
                <Table
                    columns={this.columns}
                    dataSource={data}
                ></Table>
            </div >
        )
    }
}