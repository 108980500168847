import React, { Component } from "react";
import "./style.css";
import { Tabs, Form, Button, message, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom"; // 导入 withRouter
import { loginPetsMerchant } from '../../api/login'
import request from "../../utils/request";

const TabItems = [{ key: "1", label: "账户密码登录" }];


class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //当前页面的url
            url: 'admin'
        };

    }

    componentDidMount() {
        console.log("componentDidMount");
    }
    onFinish = (value) => {
        const { url } = this.state

        if (value.phone == "admin" && value.password == "admin") {
            localStorage.setItem('admin', '1')
            this.props.history.push("/pets/account/manager");
            window.location.reload()
        } else {
            request
                .post('pets/merchant/account/login', {
                    ...value
                })
                .then(res => {
                    localStorage.setItem('type', res.type)
                    localStorage.setItem('phone', res.phone)
                    localStorage.setItem('shopId', res.shopId)
                    this.props.history.push("/pets/merchant/config");
                })
                .catch(err => {

                })
        }

    };
    render() {
        return (
            <div className="container">
                <div className="leftContent">
                    <img
                        className="leftImg"
                        src="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
                    />
                </div>
                <div className="rightContent">
                    <h3>全球最大的宠物管理平台</h3>
                    <Tabs defaultActiveKey="1" items={TabItems} />

                    <Form
                        name="normal_login"
                        style={{
                            width: "300px",
                        }}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            name="phone"
                            rules={[{ required: true, message: "请输入手机号" }]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="请输入手机号"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: "请输入密码" }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="请输入密码"
                            />
                        </Form.Item>

                        <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                            登录
                        </Button>
                    </Form>
                </div>
            </div>
        );
    }
}
export default withRouter(index);
