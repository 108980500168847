import React, { useState } from 'react';
import { Modal, Button } from 'antd';

const ModalC = ({ fields, onFinish, buttonText, children, modalWidth, onOpen }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        if (onOpen) {
            onOpen()
        }
    };

    const handleOk = () => {
        onFinish();
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return (
        <>
            <Button type="primary" onClick={handleOpenModal} style={{ width: '70px' }}>
                {buttonText || '标题'}
            </Button>
            <Modal
                width={modalWidth || 400}
                destroyOnClose={true}
                footer={null}
                title={buttonText || '标题'}
                open={isModalOpen}
                onCancel={handleCancel}
            >
                {/* {children} */}
                {React.cloneElement(children, { handleCancel })}
            </Modal>
        </>
    );


};

export default ModalC;