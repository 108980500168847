import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select, Tooltip, TimePicker, Descriptions, Image, Card, Statistic } from "antd";
import { accountListAdmin, accountRegisterAdmin, accountUpdatePasswordAdmin } from "../../../api/login";
import moment from "moment";
import request from "../../../utils/request";
import dayjs from 'dayjs';
import StaticConfig from "../../../config/StaticConfig";
import ModalC from "../../../components/ModalC";
import FormC from "../../../components/FormC";
const { RangePicker } = DatePicker
const monthFormat = 'YYYY/MM';


const innumFiled = [
    { type: 'Select', label: '宠物', name: 'pet', options: [{ label: "12", value: '221' }] },
    { type: 'Upload', label: '医疗器械照片', name: 'picture', showUploadList: false, maxCount: 1, accept: ".jpg,.png" },
    { type: 'DatePicker', label: '病例时间', name: 'date' },
    { type: 'TextArea', label: '病例内容', name: 'content' },
]

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isShowAdd: false,
            pet: [],
            logoFile: {},
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        //如果是宠物医疗店就可以添加
        const shopType = StaticConfig.SHOP_TYPE()
        console.log('shopType:', shopType)
        this.setState({
            isShowAdd: shopType == '2'
        })

        this.getAllPet()

        this.getAllTableData()
    }

    getAllTableData = () => {
        request
            .post('pets/merchant/bingli/list')
            .then(res => {
                this.setState({
                    data: res
                })
            })
            .catch(err => { })
    }

    getAllPet = () => {
        request
            .post('pets/person/pets/list', {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                const newPets = []
                res.forEach(item => {
                    const pets = {
                        label: `宠物名:${item.name} 手机号:${item.phone}`,
                        value: item._id
                    }
                    newPets.push(pets)
                })
                this.setState({
                    pet: newPets
                })
            })
            .catch(err => { })
    }

    onFinish = (value) => {

        console.log(value)
        const {
            petId,
            picture,
            content,
            date,
        } = value

        const { logoFile } = this.state

        const formData = new FormData();
        formData.append('petId', petId);
        formData.append('content', content);
        formData.append('date', date);

        if (picture.file) {
            formData.append('logo', picture.file);
        }

        request
            .post('pets/merchant/bingli/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                localStorage.setItem('shopId', res._id)
                message.open({
                    type: 'success',
                    content: '成功！'
                })
                this.getAllTableData()
            })
            .catch(err => { })
    }

    onLogoChange = (file) => {
        console.log(file)
        this.setState({ logoFile: file })
    }


    columns = [
        {
            title: '宠物名',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
                return <div >
                    {record.petId.name}
                </div>
            },
        },

        {
            title: '主人联系方式',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record, index) => {
                return <div >
                    {record.petId.phone}
                </div>
            },
        },


        {
            title: '意向图片',
            dataIndex: 'image',
            key: 'image',
            render: (text, record, index) => {
                return <Image
                    style={{ width: '30px', height: '30px' }}
                    type='primary' src={`https://www.chonglehulian.com/uploads/${record.fileName}`}
                ></Image>
                // type='primary' src={`${StaticConfig.getBaseUrl()}uploads/${record.image}`}
            },
        },

        {
            title: '病例内容',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: '日期',
            dataIndex: 'date',
            key: 'date',
            render: (text, record, index) => {
                return <div>
                    {dayjs(text * 1000).format('YYYY-MM-DD')}
                </div>
                // type='primary' src={`${StaticConfig.getBaseUrl()}uploads/${record.image}`}
            },
        }
        // {
        //     fixed: 'left',
        //     title: '操作',
        //     dataIndex: 'operate',
        //     key: 'operate',
        //     width: 300,
        //     render: (text, record, index) => {
        //         return <div style={{ display: 'flex', gap: '10px' }}>
        //             <Button type="primary" onClick={() => this.onChangeOrderState(record)}>修改状态</Button>
        //             {record.productType == '2' ? <Button type="primary" onClick={() => this.openJyDialog(record)}>寄养照片</Button> : null}
        //             {StaticConfig.SHOP_TYPE() == '2' ? <Button type="primary" onClick={() => this.openZlDialog(record)}>诊疗</Button> : null}
        //             {StaticConfig.SHOP_TYPE() == '2' ? <Button type="primary" onClick={() => this.goZhenliaoPage(record)}>查看诊疗</Button> : null}
        //             {StaticConfig.SHOP_TYPE() == '2' ? <Button type="primary" onClick={() => this.goyiliaoqixiePage(record)}>医疗器械照片</Button> : null}
        //         </div>
        //     },
        // }
    ];

    openNotification = () => {
        if ("Notification" in window) {
            // 请求用户授权
            Notification.requestPermission().then(permission => {
                console.log(permission)
                if (permission === "granted") {
                    // 创建并显示通知
                    var notification = new Notification("Hello from Web!", {
                        body: "这是一条来自网页的通知消息",
                        // icon: "path/to/icon.png"
                    });

                    // 点击通知时的行为
                    notification.onclick = function () {
                        window.open("https://example.com");
                    };
                }
            });
        }
    }

    render() {
        const { isShowAdd, pet, data } = this.state
        const uploadProps = {
            showUploadList: true,
            maxCount: 1,
            accept: "image/*", // 只接受图片文件
            beforeUpload: () => false // 阻止文件自动上传
        };

        return (
            <div className="ill-config-container">
                <div className="ill-config-search">
                    {isShowAdd ?
                        <ModalC
                            modalWidth={800}
                            buttonText='新增'>
                            <FormC
                                labelCol={4}
                                fields={[
                                    { type: 'Select', label: '宠物', name: 'petId', options: pet, selectWidth: 400 },
                                    { type: 'Upload', label: '医疗器械照片', name: 'picture', showUploadList: false, maxCount: 1, accept: ".jpg,.png", beforeUpload: () => false },
                                    { type: 'DatePicker', label: '病例时间', name: 'date' },
                                    { type: 'TextArea', label: '病例内容', name: 'content' },
                                ]}
                                onFinish={(values) => this.onFinish(values)}
                            />
                        </ModalC>
                        : null}
                    <Button onClick={this.openNotification}>通知</Button>
                </div>

                <div style={{ display: 'flex', marginTop: '30px', flexDirection: 'column' }}>
                    <Table
                        columns={this.columns}
                        dataSource={data} />
                </div>

            </div >
        )
    }
}