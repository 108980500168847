import { Layout, theme } from "antd";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Login from "./page/login";

import PetsMerchant from './page/pets/merchant'
import PetsMerchantConfig from './page/pets/config'
import PetsMerchantWashPool from './page/pets/washpool'
import ProductConfig from './page/pets/productconfig/index'
import ProductDetailsConfig from './page/pets/productdetailsconfig/index'
import Order from './page/pets/order/index'
import StorageIn from './page/pets/storage-in'
import Earn from './page/pets/earn'
import Admin from './page/pets/admin'
import Zhenliao from './page/pets/zhenliao'
import Yiliaoqixie from './page/pets/yiliaoqixie'
import ILL from './page/pets/ill'

const { Header, Content, Footer } = Layout;
function App() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Switch>
          <Route exact path="/pets/login" component={Login} />
          <Redirect exact from="/pets" to="/pets/login" />
          <Route>
            <Layout>
              <Sidebar />
              <Layout>
                <Header style={{ padding: 0 }} />
                <Content style={{ margin: "0 16px" }}>
                  <Switch>
                    <Route path="/pets/account/manage" component={PetsMerchant} />
                    <Route path='/pets/merchant/config' component={PetsMerchantConfig} />
                    <Route path='/pets/merchant/washpool' component={PetsMerchantWashPool} />
                    <Route path='/pets/product/config' component={ProductConfig} />
                    <Route path='/pets/product/details/config' component={ProductDetailsConfig} />
                    <Route path='/pets/order/order' component={Order} />
                    <Route path='/pets/storage/in' component={StorageIn} />
                    <Route path='/pets/earn/earn' component={Earn} />
                    <Route path='/pets/account/manager' component={Admin} />
                    <Route path='/pets/zhenliao/manager' component={Zhenliao} />
                    <Route path='/pets/yiliaoqixie/manager' component={Yiliaoqixie} />
                    <Route path='/pets/ill/ill' component={ILL} />
                    {/* <Route path="/fincal/user/list" component={User} /> */}

                    {/* <Route component={NotFound} /> */}
                  </Switch>
                </Content>
                <Footer style={{ textAlign: "center" }}>
                  全球最大的宠物管理平台
                </Footer>
              </Layout>
            </Layout>
          </Route>
        </Switch>
      </Layout>
    </Router >
  );
}

export default App;
