import React from "react";
import { Layout, Menu, theme } from "antd";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  ContainerOutlined,
  GroupOutlined,
  ShopOutlined
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import StaticConfig from "../config/StaticConfig";

const { Sider } = Layout;



function getItem() {
  let items = []
  const admin = localStorage.getItem('admin') || "0"
  //管理员登录
  if (admin == '1') {
    items.push(
      {
        key: "/pets/account/account",
        icon: React.createElement(ContainerOutlined),
        label: "账户管理",
        children: [
          {
            key: "/pets/account/manager",
            label: <Link to="/pets/account/manager">账户管理</Link>,
          },
        ],
      }
    )
  } else {
    const newItem = [
      {
        key: "/pets/merchant/config",
        icon: React.createElement(ShopOutlined),
        label: "店铺配置",
        children: [
          {
            key: "/pets/merchant/config",
            label: <Link to="/pets/merchant/config">店铺配置</Link>,
          },
        ],
      },

      {
        key: "/pets/product/config",
        icon: React.createElement(GroupOutlined),
        label: `${StaticConfig.SHOP_TYPE() == '1' ? "项目配置" : "医疗配置"}`,
        children: [
          {
            key: "/pets/product/config",
            label: <Link to="/pets/product/config">{StaticConfig.SHOP_TYPE() == '1' ? "项目配置" : "医疗配置"}</Link>,
          },
        ],
      },

      {
        key: "/pets/order/order",
        icon: React.createElement(ContainerOutlined),
        label: "订单管理",
        children: [
          {
            key: "/pets/order/order",
            label: <Link to="/pets/order/order">订单</Link>,
          },
        ],
      },

      {
        key: "/pets/storage/storage",
        icon: React.createElement(ContainerOutlined),
        label: "库存管理",
        children: [
          {
            key: "/pets/storage/in",
            label: <Link to="/pets/storage/in">入库</Link>,
          },
        ],
      },
      {
        key: "/pets/earn/earn",
        icon: React.createElement(ContainerOutlined),
        label: "营收管理",
        children: [
          {
            key: "/pets/earn/earn",
            label: <Link to="/pets/earn/earn">营收</Link>,
          },
        ],
      },
      {
        key: "/pets/ill/ill",
        icon: React.createElement(ContainerOutlined),
        label: "病例管理",
        children: [
          {
            key: "/pets/ill/ill",
            label: <Link to="/pets/ill/ill">病例</Link>,
          },
        ],
      },
    ];
    items = [...items, ...newItem]
  }

  items.push(
    {
      key: "/pets/logout/logout",
      icon: React.createElement(ContainerOutlined),
      label: "退出",
    }
  )
  return items
}








const onMenuClick = (item) => {
  if (item.key == '/pets/logout/logout') {
    localStorage.setItem('phone', "")
    localStorage.setItem('shopId', "")
    localStorage.setItem('admin', '0')

    // window.location.href = "http://1.94.140.241/pets/login";
    window.location.href = `${StaticConfig.getFrontBaseUrl()}pets/login`
  }
};

const Sidebar = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Sider
      collapsible
    // collapsed={collapsed}
    // onCollapse={(value) => setCollapsed(value)}
    >
      <div className="demo-logo-vertical" />
      <Menu
        onClick={onMenuClick}
        theme="dark"
        mode="inline"
        items={getItem()}
        defaultSelectedKeys={["/fincal/user/list"]}
        defaultOpenKeys={["/fincal/user/list"]}
      />
    </Sider>
  );
};
// const { Sider } = Layout;

// const Sidebar = () => {
//   return (
//     <Sider width={200} className="site-layout-background">
//       <Menu mode="inline" defaultSelectedKeys={["1"]}>
//         <Menu.Item key="1">
//           <Link to="/">login</Link>
//         </Menu.Item>
//         <Menu.Item key="2">
//           <Link to="/dashboard">dashaboard</Link>
//         </Menu.Item>
//         {/* Add more menu items here */}
//       </Menu>
//     </Sider>
//   );
// };
export default Sidebar;
