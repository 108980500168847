import React, { Component } from "react";
import "./style.css";
import { Form, Button, message, Input, Table, Upload, DatePicker, Select, Tooltip, TimePicker, Descriptions, Image, Modal, Checkbox } from "antd";
import { accountListAdmin, accountRegisterAdmin, accountUpdatePasswordAdmin } from "../../../api/login";
import moment from "moment";
import request from "../../../utils/request";
import dayjs from 'dayjs';
import { render } from "@testing-library/react";
import StaticConfig from "../../../config/StaticConfig";

const { RangePicker } = DatePicker

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isModalOpen: false,
            isShowNoFinishRemark: false,
            currentOrderId: '',
            //寄养的弹窗
            isModalJYOpen: false,

            //寄养上传的照片
            pictureFile: {},
            //当前选择的记录
            record: {},

            zhenliaoModal: false,

            //当前店铺所有的诊疗数据
            zhenliaoList: [],
            //选择的诊疗orderId
            zhenliaoOrderId: '',

        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.getData("", "0")
        this.getZhenliaoList()
    }

    getData = (phone, productType) => {
        request
            .post('pets/merchant/order/list', {
                shopId: StaticConfig.getShopId(),
                phone
            })
            .then(res => {
                console.log('productType:', productType)
                if (productType == '0') {
                    this.setState({
                        data: res
                    })
                } else {
                    const newRes = [...res]
                    const newRess = newRes.filter(item => item.productType == productType)
                    this.setState({
                        data: newRess
                    })
                }
            })
            .catch(err => { })
    }

    getDypicture = (record) => {
        request
            .post('pets/merchant/order/list/picture', {
                orderId: record._id,
            })
            .then(res => {
                this.setState({
                    dyData: res
                })
                console.log('res:', res)
            })
            .catch(err => { })
    }

    onFinish = (value) => {
        const { phone, productType } = value
        this.getData(phone, productType)
    }

    getZhenliaoList = () => {
        request
            .post('pets/merchant/cnofigdetails/all', {
                phone: StaticConfig.getPhone()
            })
            .then(res => {
                const newRes = [...res]

                const nnRes = newRes.map(obj => ({
                    ...obj,
                    'label': obj.productTitle,
                    'value': obj._id,
                }))
                this.setState({
                    zhenliaoList: nnRes
                })
            })
            .catch(err => {

            })
    }
    render() {
        //不让他选择日期，防止有些商家故意上传还没有服务的日期图片，影响用户体验
        const uploadProps = {
            showUploadList: false,
            maxCount: 1,
            customRequest: this.handleUpload,
        };


        const { data, isModalOpen, isShowNoFinishRemark, isModalJYOpen, dyData, zhenliaoModal, zhenliaoList } = this.state
        return (
            <div className="order-config-container">
                <div className="order-config-search">
                    <Form
                        layout="inline"
                        ref={this.formRef}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        onFinish={this.onFinish}
                    >

                        <Form.Item label="手机号" name="phone"
                        >
                            <Input
                            />
                        </Form.Item>

                        <Form.Item

                            style={{
                                width: 230,
                            }}
                            label="类型" name="productType"
                            rules={[
                                {
                                    required: true,
                                }]}
                        >
                            <Select
                                defaultValue={'0'}
                                options={
                                    StaticConfig.SHOP_TYPE() == '1' ? [
                                        {
                                            value: '0',
                                            label: '全部',
                                        },
                                        {
                                            value: '1',
                                            label: '美容',
                                        },
                                        {
                                            value: '2',
                                            label: '寄养',
                                        }
                                    ] :
                                        [
                                            {
                                                value: '0',
                                                label: '全部',
                                            },
                                            {
                                                value: '3',
                                                label: '问诊',
                                            },
                                            {
                                                value: '4',
                                                label: '急诊',
                                            }
                                        ]
                                }
                            />
                        </Form.Item>

                        <Button type="primary" style={{ marginLeft: '10px' }} htmlType="submit">提交</Button>
                    </Form>

                </div>

                <Table
                    scroll={{
                        x: 'max-content',
                    }}
                    columns={this.columns}
                    dataSource={data}
                ></Table>

                <Modal
                    title="订单状态"
                    open={isModalOpen}
                    onCancel={this.handleCancel}
                    destroyOnClose
                    footer={null}>
                    <Form
                        // layout="inline"
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                            maxHeight: 500
                        }}
                        onFinish={this.statusonFinish}
                    >

                        <Form.Item label="状态" name="status"
                            style={{ marginTop: '30px' }}
                            rules={[
                                {
                                    required: true,
                                    message: '请选择状态',
                                },
                            ]}
                        >
                            <Select

                                style={{
                                    width: 120,
                                }}
                                onChange={this.statushandleChange}
                                options={[
                                    {
                                        value: '1',
                                        label: '已完成',
                                    },
                                    {
                                        value: '2',
                                        label: '未完成',
                                    },
                                ]}
                            />
                        </Form.Item>

                        {isShowNoFinishRemark ?
                            <Form.Item label="未完成原因"
                                name="nofinishremark"
                                style={{ marginTop: '30px' }}
                                rules={[
                                    {
                                        required: true,
                                        message: '请填写原因',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="未完成原因"
                                />
                            </Form.Item> : null
                        }

                        <Button type="primary" style={{ marginLeft: '10px', marginTop: '50px' }} htmlType="submit">提交</Button>
                    </Form>

                </Modal>


                {/* 寄养弹窗 */}
                <Modal
                    width={1200}
                    height={1600}
                    title="寄养照片管理"
                    open={isModalJYOpen}
                    onCancel={this.closeJyDiaolog}
                    destroyOnClose
                    footer={null}>


                    <Upload
                        {...uploadProps}
                    >
                        <Button type="primary">照片上传</Button>
                    </Upload>
                    <Table
                        style={{ marginTop: '20px' }}
                        columns={this.jyColumns}
                        dataSource={dyData}
                    ></Table>


                </Modal>



                {/* 诊疗弹窗 */}
                <Modal
                    width={800}
                    title="诊疗"
                    open={zhenliaoModal}
                    onCancel={this.handleZlCancel}
                    destroyOnClose
                    footer={null}>
                    <Form
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                            maxHeight: 500
                        }}
                        onFinish={this.zhenliaoAddFinish}
                    >

                        <Form.Item label="服务" name="service"
                            style={{ marginTop: '30px' }}
                            rules={[
                                {
                                    required: true,
                                    message: '请选择状态',
                                },
                            ]}
                        >
                            <Checkbox.Group options={zhenliaoList} />

                        </Form.Item>

                        <Button type="primary" style={{ marginLeft: '10px', marginTop: '50px' }} htmlType="submit">提交</Button>
                    </Form>

                </Modal>
            </div >
        )
    }

    handleUpload = ({ file }) => {
        const { record } = this.state

        const formData = new FormData();
        formData.append('orderId', record._id);
        formData.append('file', file);
        formData.append('date', moment().startOf('day').unix());

        request
            .post('pets/merchant/order/upload/picture', formData, {
                headers: {
                    'Content-Type': 'multipart/sform-data'
                }
            })
            .then(res => {
                message.open({
                    type: 'success',
                    content: '成功！'
                })
                this.getDypicture(record)

            })
            .catch(err => { })

    }

    jyFinish = () => {

    }

    openJyDialog = (record) => {
        this.getDypicture(record)
        this.setState({
            isModalJYOpen: true,
            record
        })
    }

    closeJyDiaolog = () => {
        this.setState({
            isModalJYOpen: false
        })
    }

    statushandleChange = (values) => {
        console.log(values)
        if (values === '2') {
            this.setState({
                isShowNoFinishRemark: true
            })
        } else {
            this.setState({
                isShowNoFinishRemark: false
            })
        }
    }

    statusonFinish = (values) => {
        console.log(values)

        const { currentOrderId } = this.state
        const { _id, status, nofinishremark } = values
        request
            .post('pets/merchant/order/update', {
                _id: currentOrderId,
                status,
                nofinishremark
            })
            .then(res => {

                this.setState({
                    isModalOpen: false
                })
                this.getData("", "0")

            })
            .catch(err => { })

    }


    handleCancel = () => {
        this.setState({
            isModalOpen: false
        })
    }

    onChangeOrderState = (item) => {
        console.log(item)
        this.setState({
            isModalOpen: true,
            currentOrderId: item._id
        })
    }
    genText = (text) => {
        if (text == '0') {
            return '已下单'
        } else if (text == '1') {
            return '已完成'
        } else if (text == '2') {
            return '未完成'
        } else if (text == '3') {
            return '已取消'
        }
        return text
    }

    columns = [
        {
            title: '编号',
            dataIndex: '_id',
            key: '_id',
        },
        //0已下单 1是已完成 2是未完成
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => {
                let color;
                switch (text) {
                    case '0':
                        color = '#FFA500';
                        break;
                    case '1':
                        color = '#45B9BB';
                        break;
                    case '2':
                        color = '#FF0000';
                        break;
                    case '3':
                        color = '#FF8282';
                        break;
                }
                return <div style={{ color: 'white', backgroundColor: color, textAlign: 'center', height: '26px', lineHeight: '26px', width: '60px', borderRadius: '6px' }}>
                    {this.genText(text)}
                </div>
            },
        },
        {
            title: '店铺名称',
            dataIndex: 'shopName',
            key: 'shopName',
        },

        {
            title: '项目名称',
            dataIndex: 'productName',
            key: 'productName',
        },

        {
            title: '项目金额',
            dataIndex: 'productFee',
            key: 'productFee',
        },
        {
            title: '诊疗金额',
            dataIndex: 'zhenliaoFee',
            key: 'zhenliaoFee',
        },


        {
            title: '预约时间',
            dataIndex: 'orderTime',
            key: 'orderTime',
        },

        {
            title: '宠物名',
            dataIndex: 'petName',
            key: 'petName',
        },

        {
            title: '宠物种类',
            dataIndex: 'petType',
            key: 'petType',
        },
        {
            title: '客户电话',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '意向图片',
            dataIndex: 'image',
            key: 'image',
            render: (text, record, index) => {
                return <Image
                    style={{ width: '30px', height: '30px' }}
                    type='primary' src={`https://www.chonglehulian.com/uploads/${record.image}`}
                ></Image>
                // type='primary' src={`${StaticConfig.getBaseUrl()}uploads/${record.image}`}
            },
        },

        {
            title: '下单时间',
            dataIndex: 'createAt',
            key: 'createAt',
        },
        {
            title: '未完成原因',
            dataIndex: 'nofinishremark',
            key: 'nofinishremark'
        },
        {
            title: '项目类型',
            dataIndex: 'productType',
            key: 'productType',
            render(text, record, index) {
                return <div> {StaticConfig.Product.get(text)}</div>
            }
        },
        {
            title: '取宠时间',
            dataIndex: 'getTimePets',
            key: 'getTimePets',

        },
        {
            fixed: 'left',
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            width: 300,
            render: (text, record, index) => {
                return <div style={{ display: 'flex', gap: '10px' }}>
                    <Button type="primary" onClick={() => this.onChangeOrderState(record)}>修改状态</Button>
                    {record.productType == '2' ? <Button type="primary" onClick={() => this.openJyDialog(record)}>寄养照片</Button> : null}
                    {StaticConfig.SHOP_TYPE() == '2' ? <Button type="primary" onClick={() => this.openZlDialog(record)}>诊疗</Button> : null}
                    {StaticConfig.SHOP_TYPE() == '2' ? <Button type="primary" onClick={() => this.goZhenliaoPage(record)}>查看诊疗</Button> : null}
                    {StaticConfig.SHOP_TYPE() == '2' ? <Button type="primary" onClick={() => this.goyiliaoqixiePage(record)}>医疗器械照片</Button> : null}
                </div>
            },
        }
    ];


    goyiliaoqixiePage = (record) => {
        this.props.history.push('/pets/yiliaoqixie/manager', { record })
    }


    goZhenliaoPage = (record) => {
        this.props.history.push('/pets/zhenliao/manager', { record })
    }

    openZlDialog = (record) => {
        this.setState({
            zhenliaoModal: true,
            zhenliaoOrderId: record._id,
        })
    }

    handleZlCancel = () => {
        this.setState({
            zhenliaoModal: false
        })
    }
    zhenliaoAddFinish = (values) => {
        const { zhenliaoOrderId } = this.state
        // console.log('record:', record)
        console.log('values:', values)
        console.log('zhenliaoOrderId:', zhenliaoOrderId)
        request
            .post('pets/merchant/order/update/zhenliao', {
                _id: zhenliaoOrderId,
                zhenliaoList: values.service
            })
            .then(res => {
                this.getData("", "0")
                this.handleZlCancel()
            })
            .catch(err => {

            })
    }

    jyColumns = [
        {
            title: '时间',
            dataIndex: 'date',
            key: 'date',
            render(text, record) {
                return <div>
                    {moment.unix(text).format('YYYY-MM-DD')}
                </div>
            }
        },

        {
            title: '图片',
            dataIndex: 'image',
            key: 'image',
            render: (text, record, index) => {
                return <Image
                    style={{ width: '80px', height: '80px' }}
                    type='primary' src={`${StaticConfig.getBaseUrl()}uploads/${record.fileName}`}
                ></Image>
            },
        },
    ];
}