import React, { useEffect } from 'react';
import { Form, Input, Select, DatePicker, Button, Divider, InputNumber, Upload } from 'antd';
import dayjs from 'dayjs';
import './style.css'
const { Option } = Select;
const { RangePicker } = DatePicker
const { TextArea } = Input;

const FormComponent = ({ fields, onFinish, layout, labelCol, handleCancel, initialValues, isShowConfirm = true, beforeUpload }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (initialValues) {
            const convertedValues = { ...initialValues };
            fields.forEach(field => {
                if (field.type === 'DatePicker' && initialValues[field.name]) {
                    convertedValues[field.name] = dayjs.unix(initialValues[field.name]);
                }
            });
            form.setFieldsValue(convertedValues);
        }
    }, [initialValues, form, fields]);

    const renderField = (field) => {
        const { type, label, name, options, style, placeHolder, controlWidth, selectWidth, multiple, ...rest } = field;

        switch (type) {
            case 'Input':
                return <Input
                    {...rest}
                    placeHolder={placeHolder}
                />;
            case 'InputNumber':
                return <Input
                    {...rest}
                    type='number'
                    placeHolder={placeHolder}
                />;
            case 'Select':
                return (
                    <Select
                        style={{ width: !selectWidth ? 240 : selectWidth, ...style }}
                        {...rest}
                        {...multiple ? { mode: "multiple" } : {}}
                    >
                        {options.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                );
            case 'DatePicker':
                return <DatePicker {...rest} />;
            case 'Divider':
                return <Divider orientation="left">{label}</Divider>
            case 'RangePicker':
                return <RangePicker />
            case "Upload":
                return <Upload beforeUpload={beforeUpload} {...rest}>
                    <Button>上传</Button>
                </Upload>
            case "TextArea":
                return <TextArea />
            default:
                return <Input {...rest} />;
        }
    };

    const handleFinish = (values) => {
        const convertedValues = { ...values };
        fields.forEach(field => {
            if (field.type === 'DatePicker' && values[field.name]) {
                convertedValues[field.name] = dayjs(values[field.name]).unix();
            }
        });
        onFinish(convertedValues);
        handleCancel();
    };

    return (
        <Form
            form={form}
            labelCol={{ span: labelCol || 6 }}
            wrapperCol={{ span: 16 }}
            layout={layout || 'horizontal'}
            onFinish={handleFinish}
        >
            {fields.map((field) => {
                const { visiable = true } = field;
                const fieldElement = renderField(field);

                if (!visiable) {
                    return fieldElement;
                }

                return (
                    <Form.Item
                        key={field.name}
                        label={field.label}
                        name={field.name}
                        rules={field.rules || [{ required: true, message: `请输入${field.label.toLowerCase()}!` }]}
                        style={{ marginBottom: 12 }}
                    >
                        {fieldElement}
                    </Form.Item>
                )
            })}
            {isShowConfirm ? <Form.Item>
                <Button type="primary" htmlType="submit">
                    确定
                </Button>
            </Form.Item> : null}

        </Form>
    );
};

export default FormComponent;
